<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeInRight animate__fast"
      leave-active-class="animate__animated animate__fadeOutRight animate__fast"
    >
      <div
        class="flex flex-col side-cart z-hard shadow-2xl subway_sixinch"
        v-if="showCart"
      >
        <div class="h-full flex flex-col z-1">
          <div class="flex items-center justify-between border-b">
            <button
              class="flex items-center justify-between py-2 lg:py-4 px-4 w-full text-black bg-primary side-cart-top"
              @click="$emit('closeCard')"
            >
              <span class="text-white b pl-4 fs-20">{{$t('Shopping Cart')}}</span>
              <div class="flex aic jcc w-40p h-40p">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
              </div>
            </button>
          </div>
          <div class="flex-1 h-0 overflow-y-auto pb-48 side-cart-in">
            <div class="flex-1 flex flex-col justify-between pt-4">
              <div class="flex flex-col px-4 sm:px-6 space-y-4">
                <div
                  class="flex aic jcb w-full shadow-glob py-2 px-4 rounded-md animate__animated animate__fadeIn animate__fast"
                  v-for="product in ShoppingCart"
                  :key="product.id"
                >
                  <div class="flex ais py-3 w-full space-x-4">
                    <div class="bg-white p-2 rounded-xl">
                      <img
                        :src="
                            product.image
                              ? storageUrl + product.image
                              : '/dish.webp'
                          "
                        alt="checkout-image"
                        class="w-70p"
                      />
                    </div>
                    <div class="flex flex-col jcc w-full space-y-5">
                      <div class="flex ais jcb w-full">
                        <div class="flex flex-col cart-title">
                          <h2 class="fs-13 w-full whitespace-wrap text-gray-900 m-0 p-0 capitalize b tracking-wide">
                            {{ product.name }}
                          </h2>
                          <div
                            v-if="product.is_package == 1"
                            class="flex w-full mt-1 flex-col text-gray-600"
                          >
                            <span
                              class="fs-11 capitalize whitespace-nowrap"
                              v-for="(item, i) in product.items"
                              :key="i"
                            >
                              {{item.name}}
                              <!-- <span
                                  class="mr-1"
                                  v-if="i < product.items.length - 1"
                                >,</span> -->
                            </span>
                          </div>
                          <div
                            v-if="product.is_package !== 1"
                            class="flex w-full mt-1 flex-col"
                          >
                            <span
                              class="fs-11 capitalize whitespace-nowrap"
                              v-for="(item, i) in product.items"
                              :key="i"
                            >
                              {{item.item}}
                              <!-- <span
                                  class="mr-1"
                                  v-if="i < product.items.length - 1"
                                >,</span> -->
                            </span>
                          </div>
                        </div>
                        <button
                          class="h-30p w-30p flex aic jcc remove-cart bg-gray text-primary-dark rounded-md"
                          @click="deletFromBasket(product)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>

                      <div class="flex aic jcb w-full">
                        <div class="flex aic w-110p rounded-2xl px-1">
                          <button
                            class="flex aic jcc w-30p h-30p bg-gray rounded-full"
                            @click="remove(product)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M18 12H6"
                              />
                            </svg>
                          </button>
                          <div class="flex aic jcc bg-white text-center text-primary b tracking-wider fs-13 w-40p px-2 h-23p">
                            {{ product.quantity }}
                          </div>

                          <button
                            @click="add(product)"
                            class="flex aic jcc w-30p h-30p bg-gray rounded-full"
                            :class="{
                                'cursor-not-allowed': product.is_package != 0,
                              }"
                            :disabled="product.is_package != 0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                          </button>
                        </div>
                        <span class="fs-13 font-bold text-gray-800 open-sansflex aie">
                          <span class="font-normal mr-2 fs-10">{{ currency }}</span>
                          {{ product.totalPrice }}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="flex-col flex justify-end fixed bottom-0 right-0 w-full cart-btn z-1">
          <div class="flex flex-col aic py-6 subway_footlong w-full text-sm px-5 bg-gray">
            <div class="flex w-full aic jcb fs-16">
              <span class="text-gray-500 tracking-wide">{{$t("Total Price")}}</span><span class="flex aib fs-18 text-primary-dark"><small class="fs-12 mr-2 subway_sixinch">{{ currency }}</small>{{ TotalPrice }}</span>
            </div>
          </div>
          <button
            @click="goCheckOut"
            aria-label="Proceed to Checkout"
            class="w-full py-4 btn-secondary text-lg b tracking-wider"
          >
            {{$t("Checkout")}}
          </button>
        </div>

      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="cart-overlay"
        v-if="showCart"
        @click="$emit('closeCard')"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "SideCart",
  props: ["showCart"],
  computed: {
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    ShoppingCart() {
      return this.$store.state.orderiomApiPackage.product.ShoppingCart;
    },
    TotalPrice() {
      return this.$store.state.orderiomApiPackage.product.TotalPrice || "0.00";
    },
    SubtotalPrice() {
      return (
        this.$store.state.orderiomApiPackage.product.SubtotalPrice || "0.00"
      );
    },
    counter() {
      let quantity = this.ShoppingCart.map((m) => {
        return m.quantity;
      });
      let count = quantity.reduce(function (a, b) {
        return a + b;
      }, 0);
      return count;
    },
    shippingPrice() {
      return (
        this.$store.state.orderiomApiPackage.product.shippingPrice || "0.00"
      );
    },
    totalWithDiscount() {
      return this.$store.state.orderiomApiPackage.product.totalWithDiscount;
    },
  },
  methods: {
    goCheckOut() {
      if (this.$route.path != "/cart") this.$router.push("/cart");
      this.$emit("closeCard");
    },
    add(product) {
      this.$store.dispatch("order/addToBasket", {
        productId: product.RestaurantProductId,
        attributeItems: product.items.map((m) => {
          return m.id;
        }),
        restaurantId: this.restaurantId,
      });
    },
    deletFromBasket(product) {
      this.$store.dispatch("order/deleteFromBasket", {
        productId: product.RestaurantProductId,
        restaurantId: localStorage.getItem("restaurantId"),
      });
    },
    remove(product) {
      this.$store.dispatch("order/removeFromBasketWithAttr", {
        basketProductId: product.basketProductId,
        restaurantId: this.restaurantId,
      });
    },
  },
};
</script>
