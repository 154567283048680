<template>
  <div>
    <div class="md:hidden fixed p-3 bottom-0 left-0 w-full mobile-nav-area">
      <div class="flex aic jcb w-full bg-secondary mobile-nav rounded-xl">
        <router-link
          exact-active-class="exact-active"
          to="/dashboard"
          class="py-3 px-5 h-60p flex flex-col aic jcc mob-link"
        >
          <div class="exact-nav">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
          </div>
        </router-link>
        <router-link
          exact-active-class="exact-active"
          to="/dashboard/anlegen"
          class="py-3 px-5 h-55p flex flex-col aic jcc mob-link"
        >
          <div class="exact-nav">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
            </svg>
          </div>
        </router-link>
        <router-link
          exact-active-class="exact-active"
          to="/dashboard/ansehen-anderen"
          class="py-3 px-5 h-55p flex flex-col aic jcc mob-link"
        >
          <div class="exact-nav">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
          </div>
        </router-link>
        <router-link
          exact-active-class="exact-active"
          to="/dashboard/zusatzbestellung"
          class="py-3 px-5 h-55p flex flex-col aic jcc mob-link"
        >
          <div class="exact-nav">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </router-link>
        <router-link
          exact-active-class="exact-active"
          to="/dashboard/unterbrechen"
          class="py-3 px-5 h-55p flex flex-col aic jcc mob-link"
        >
          <div class="exact-nav">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </router-link>

      </div>
    </div>
    <SideCart
      @closeCard="showCart = false"
      :showCart="showCart"
    ></SideCart>

    <NavAccount
      :showAccountModal="showAccountModal"
      @close="showAccountModal = false"
    ></NavAccount>
  </div>

</template>
<script>
import NavAccount from "./NavAccount.vue";
import SideCart from "./SideCart.vue";

export default {
  name: "Header",
  components: { SideCart, NavAccount },
  data() {
    return {
      showCart: false,
      showAuthModal: false,
      showAccountModal: false,
    };
  },
  computed: {
    ShoppingCart() {
      return this.$store.state.orderiomApiPackage.product.ShoppingCart;
    },
    counter() {
      let quantity = this.ShoppingCart.map((m) => {
        return m.quantity;
      });
      let count = quantity.reduce(function (a, b) {
        return a + b;
      }, 0);
      return count;
    },
    logo() {
      return process.env.VUE_APP_LOGO;
    },
    isLogin() {
      return this.$store.state.orderiomApiPackage.auth.privateToken;
    },
    user() {
      return this.$store.state.orderiomApiPackage.auth.user;
    },
  },
};
</script>
