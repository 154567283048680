<template>
  <div class="">
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="bg-white fixed top-0 left-0 w-screen h-screen z-hard overflow-y-scroll overflow-x-hidden"
        v-if="showAccountModal"
      >
        <button
          @click="$emit('close')"
          class="fixed right-0 lg:right-10 top-0 z-hard animate__animated animate__zoomIn p-8"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 lg:h-8 lg:w-8 opacity-50"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div class="nav-account flex flex-col border-r border-gray-200 bg-white z-hard">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto side-menu-in">
            <nav
              class="mt-5 flex-1 flex flex-col space-y-1 side-nav"
              aria-label="Sidebar"
            >
              <router-link
                to="/profile"
                class="sn-a w-full flex aic px-9 py-4 text-sm relative cursor-pointer active"
              >
                <div class="flex aic w-full z-hard relative">
                  <div class="dash-icon">
                    <img
                      src="/img/sideprofile.webp"
                      alt="Profile"
                      class="w-35p h-35p object-cover mt-2"
                    />
                  </div>
                  <span class="hidden lg:block">Profile</span>
                </div>
              </router-link>
              <button class="sn-a w-full flex aic px-9 py-4 text-sm relative cursor-pointer">
                <div class="flex aic w-full z-hard relative">
                  <div class="dash-icon">
                    <img
                      src="/img/orderhistory.webp"
                      alt="Profile"
                      class="w-35p h-35p object-cover"
                    />
                  </div>
                  Your Orders
                </div>
              </button>
            </nav>
          </div>
          <div class="flex flex-col side-nav-bottom">
            <button class="sn-a w-full flex aic px-9 py-4 text-sm relative cursor-pointer border-t">
              <div class="flex aic w-full z-hard relative">
                <div class="dash-icon mr-2">
                  <img
                    src="/img/logout.webp"
                    alt="Profile"
                    class="w-35p h-35p object-cover"
                  />
                </div>
                Logout
              </div>
            </button>
          </div>
        </div>

        <div class="overlay"></div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: ["showAccountModal"],
  data() {
    return {
      level: "Login",
    };
  },
};
</script>
