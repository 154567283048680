<template>
  <div class="flex aic justify-between md:justify-end w-full bg-black dashboard-top shadow-xl py-3 md:space-x-8 px-6">
    <button
      type="button"
      @click="logOut"
      class="flex aic text-gray-300 rounded-xl text-sm order-2 md:order-1"
    >
      Ausloggen
      <svg
        class="h-4 w-4 ml-2"
        fill="#cccccc"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 22h-20v-7h2v5h16v-16h-16v5h-2v-7h20v20zm-13-11v-4l6 5-6 5v-4h-11v-2h11z" />
      </svg>
    </button>
    <div class="flex aic md:space-x-3 md:order-2">
      <span class="text-white fs-15 font-medium order-2 md:order-1">{{user.name}}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4 mr-3 md:mr-0 md:order-2"
        fill="#ff9f00"
        viewBox="0 0 24 24"
      >
        <path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.orderiomApiPackage.auth.user;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout", this.restaurantId).then(() => {
        this.$router.push("/");
        localStorage.removeItem("basket");
      });
    },
  },
};
</script>
