<template>
  <div class="dash-body">
    <main class="ml-auto dash-main">
      <Header />
      <router-view />
    </main>
    <MobileNav />
    <!-- <Footer /> -->
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";
import MobileNav from "@/components/MobileNav.vue";

export default {
  name: "Home",
  components: {
    Header,
    MobileNav,
    // Footer,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    restaurantInfo() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo;
    },
  },
  mounted() {
    this.$store.dispatch("auth/tryAutoLogin").then(() => {
      this.$store
        .dispatch(
          "restaurant/getRestaurantInfo",
          localStorage.getItem("restaurantId")
        )
        .then(() => (this.$i18n.locale = this.restaurantInfo.lang));
      this.$store.dispatch(
        "restaurant/getImprint",
        localStorage.getItem("restaurantId")
      );
      if (
        localStorage.getItem("basket") &&
        JSON.parse(localStorage.getItem("basket")).length
      ) {
        this.$store.dispatch(
          "product/getBasket",
          localStorage.getItem("restaurantId")
        );
      }
    });
  },
};
</script>
<style>
</style>
